import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import '../assets/scss/components/_rigthBanner.scss';

const RigthBanner = () => {
  // Fetch the image using GraphQL
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 260, height: 300)
            }
          }
        }
      }
    }
  `);

  // Find the specific image by relativePath
  const imageNode = data.allFile.edges.find(
    ({ node }) => node.relativePath === "quranplan-side.png"
  );

  if (!imageNode) {
    console.error("Image not found: quranplan-side.png");
    return null;
  }

  const image = getImage(imageNode.node.childImageSharp);

  return (
    <a
      href="https://www.quranplan.com/"
      target="_blank"
      className="RigthBanner-cont"
      rel="noopener noreferrer"
    >
      <GatsbyImage image={image} alt="banner" />
    </a>
  );
};

export default RigthBanner;
